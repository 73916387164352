import { v4 } from "uuid";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Field, Form } from "formik";

import {
	Button,
	Checkbox,
	InputAdornment,
	CircularProgress,
} from "@mui/material";

import {
	ref,
	uploadBytes,
	deleteObject,
	getDownloadURL,
} from "firebase/storage";

import {
	setDataState,
	displayNotification,
} from "../../store/reducers/notificationSlice";

import { STORAGE } from "../../firebase";
import { addProduct } from "../adminPanel.actions";
import { ADMIN_PANEL_TABS } from "../AdminPanelContainer";
import SelectFormik from "../../components/formik/SelectFormik";
import InputFieldFormik from "../../components/formik/InputFieldFormik";
import { DATA_STATE, NOTIFICATION_TYPES } from "../../helpers/app.constants";
import SelectMultipleFormik from "../../components/formik/SelectMultipleFormik";

const FORM_FIELDS = {
	TITLE: "title",
	DESCRIPTION: "description",
	PRICE: "price",
	CATEGORY: "category",
	BRAND: "brand",
	DISCOUNT_AMOUNT: "discountAmount",
	ISNEW: "newProduct",
	SECOND_IMAGE: "secondImage",
	THIRD_IMAGE: "thirdImage",
	IMAGE: "image",
	FLAVORS: "flavors",
};

const AdminPanelAddNewProductForm = ({
	brands,
	categories,
	handleSettingActiveTab,
}) => {
	const dispatch = useDispatch();
	const [image, setImage] = useState(null);
	const [image2, setImage2] = useState(null);
	const [image3, setImage3] = useState(null);
	const [flavors, setFlavors] = useState([]);
	const [inputValue, setInputValue] = useState("");
	const [editIndex, setEditIndex] = useState(null);

	const initialValues = useMemo(() => {
		return {
			[FORM_FIELDS.TITLE]: null,
			[FORM_FIELDS.DESCRIPTION]: null,
			[FORM_FIELDS.PRICE]: null,
			[FORM_FIELDS.CATEGORY]: null,
			[FORM_FIELDS.BRAND]: null,
			[FORM_FIELDS.IMAGE]: null,
			[FORM_FIELDS.DISCOUNT_AMOUNT]: 0,
			[FORM_FIELDS.ISNEW]: true,
			[FORM_FIELDS.SECOND_IMAGE]: null,
			[FORM_FIELDS.THIRD_IMAGE]: null,
			[FORM_FIELDS.FLAVORS]: [],
		};
	}, []);

	const validationSchema = Yup.object().shape({
		[FORM_FIELDS.TITLE]: Yup.string().required("Proizvod mora imati ime!"),
		[FORM_FIELDS.DESCRIPTION]: Yup.string().required(
			"Proizvod mora imati opis!",
		),
		[FORM_FIELDS.PRICE]: Yup.number().required("Proizvod mora imati cenu!"),
		[FORM_FIELDS.CATEGORY]: Yup.array()
			.required("Proizvod mora imati kategoriju!")
			.min(1, "Proizvod mora imati bar jednu kategoriju!"),
		[FORM_FIELDS.BRAND]: Yup.string().required("Proizvod mora imati brend!"),
		[FORM_FIELDS.IMAGE]: Yup.string().required("Proizvod mora imati sliku!"),
	});

	const onSubmit = (formValues) => {
		const newProduct = {
			[FORM_FIELDS.TITLE]: formValues[FORM_FIELDS.TITLE],
			[FORM_FIELDS.DESCRIPTION]: formValues[FORM_FIELDS.DESCRIPTION],
			[FORM_FIELDS.PRICE]: parseInt(formValues[FORM_FIELDS.PRICE]),
			[FORM_FIELDS.CATEGORY]: formValues[FORM_FIELDS.CATEGORY],
			[FORM_FIELDS.BRAND]: formValues[FORM_FIELDS.BRAND],
			[FORM_FIELDS.IMAGE]: formValues[FORM_FIELDS.IMAGE],
			[FORM_FIELDS.QUANTITY]: parseInt(formValues[FORM_FIELDS.QUANTITY]),
			[FORM_FIELDS.DISCOUNT_AMOUNT]: parseInt(
				formValues[FORM_FIELDS.DISCOUNT_AMOUNT],
			),
			[FORM_FIELDS.ISNEW]: formValues[FORM_FIELDS.ISNEW],
			[FORM_FIELDS.SECOND_IMAGE]: formValues[FORM_FIELDS.SECOND_IMAGE],
			[FORM_FIELDS.THIRD_IMAGE]: formValues[FORM_FIELDS.THIRD_IMAGE],
			[FORM_FIELDS.FLAVORS]: flavors,
		};
		dispatch(addProduct(newProduct)).then(() =>
			handleSettingActiveTab(ADMIN_PANEL_TABS.ALL_PRODUCTS),
		);
	};

	const handleImageDelete = (form, field) => {
		let imageRef = null;
		if (field === FORM_FIELDS.IMAGE) {
			imageRef = ref(STORAGE, image);
		} else if (field === FORM_FIELDS.SECOND_IMAGE) {
			imageRef = ref(STORAGE, image2);
		} else if (field === FORM_FIELDS.THIRD_IMAGE) {
			imageRef = ref(STORAGE, image3);
		}
		deleteObject(imageRef)
			.then(() => {
				if (field === FORM_FIELDS.IMAGE) {
					setImage(null);
				} else if (field === FORM_FIELDS.SECOND_IMAGE) {
					setImage2(null);
				} else if (field === FORM_FIELDS.THIRD_IMAGE) {
					setImage3(null);
				}
				form.setFieldValue(field, null);
				const notificationPayload = {
					text: "Slika je uspešno izbrisana!",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
			})
			.catch(() => {
				const notificationPayload = {
					text: "Došlo je do greške!",
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleAddFlavor = (form) => {
		if (inputValue.trim() !== "") {
			if (editIndex !== null) {
				const updatedFlavors = flavors.map((flavor, index) =>
					index === editIndex ? inputValue : flavor,
				);
				setFlavors(updatedFlavors);
				form.setFieldValue(FORM_FIELDS.FLAVORS, updatedFlavors);
				setEditIndex(null);
			} else {
				setFlavors([...flavors, inputValue]);
				form.setFieldValue(FORM_FIELDS.FLAVORS, [...flavors, inputValue]);
			}

			setInputValue("");
		}
	};

	const handleEditFlavor = (index) => {
		setInputValue(flavors[index]);
		setEditIndex(index);
	};

	const handleDeleteFlavor = (index, form) => {
		const updatedFlavors = flavors.filter((flavor, i) => i !== index);
		setFlavors(updatedFlavors);
		form.setFieldValue(FORM_FIELDS.FLAVORS, [...flavors, updatedFlavors]);
	};

	const adminAddFormContainerStyles = {
		height: "94%",
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		overflow: "auto",
		backgroundColor: "white",
		borderBottomLeftRadius: "5px",
		borderBottomRightRadius: "5px",
	};
	const adminInputContainerStyles = {
		minWidth: "150px",
		width: "49%",
	};

	const adminAddProductContainerStyles = {
		borderRadius: "5px",
		padding: "15px",
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		justifyContent: "space-between",
		width: "400px",
		height: "75vh",
		marginLeft: "10px",
	};

	const adminAddProductFormStyles = {
		gap: "5px",
		display: "flex",
		flexDirection: "row",
		height: "110%",
		flexWrap: "wrap",
		paddingTop: "100px",
	};

	const adminAddProductFieldContainerStyles = {
		display: "flex",
		justifyContent: "space-between",
	};

	return (
		<div style={adminAddFormContainerStyles}>
			<Formik
				validateOnMount
				initialValues={initialValues}
				validationSchema={validationSchema}
			>
				{({ values, isValid }) => (
					<Form style={adminAddProductFormStyles}>
						<div style={adminAddProductContainerStyles}>
							<div>
								<Field name={FORM_FIELDS.TITLE}>
									{({ form, ...formik }) => (
										<InputFieldFormik
											form={form}
											{...formik}
											label="Ime"
											sx={{ width: "100%" }}
											fullWidth
											size="small"
										/>
									)}
								</Field>
							</div>

							<div style={{ height: "200px", marginBottom: "50px" }}>
								<Field name={FORM_FIELDS.DESCRIPTION}>
									{({ field }) => (
										<ReactQuill
											style={{ height: "150px", width: "100%", color: "black" }}
											value={field.value || ""}
											onChange={field.onChange(field.name)}
											placeholder={"Opis proizvoda"}
										/>
									)}
								</Field>
							</div>

							<div style={adminAddProductFieldContainerStyles}>
								<Field name={FORM_FIELDS.PRICE}>
									{({ form, ...formik }) => (
										<InputFieldFormik
											form={form}
											{...formik}
											label="Cena"
											sx={adminInputContainerStyles}
											size="small"
											InputProps={{
												endAdornment: (
													<InputAdornment position="start">rsd</InputAdornment>
												),
											}}
										/>
									)}
								</Field>
								<Field name={FORM_FIELDS.DISCOUNT_AMOUNT}>
									{({ form, ...formik }) => (
										<InputFieldFormik
											form={form}
											{...formik}
											label="Popust"
											sx={adminInputContainerStyles}
											size="small"
											InputProps={{
												endAdornment: (
													<InputAdornment position="start">%</InputAdornment>
												),
											}}
										/>
									)}
								</Field>
							</div>
							<div style={{...adminAddProductFieldContainerStyles, marginBottom:"20px"}}>
								<Field name={FORM_FIELDS.BRAND}>
									{({ form, ...formik }) => (
										<SelectFormik
											values={brands}
											form={form}
											{...formik}
											title="Brend"
										/>
									)}
								</Field>
							</div>
							{!categories ? (
								<CircularProgress />
							) : (
								<Field name={FORM_FIELDS.CATEGORY}>
									{({ form, ...formik }) => (
										<SelectMultipleFormik
											values={categories}
											form={form}
											{...formik}
											title="Kategorija"
										/>
									)}
								</Field>
							)}

							<div
								style={{
									...adminAddProductFieldContainerStyles,
									justifyContent: "space-around",
									marginTop: "5px",
								}}
							>
								<Field name={FORM_FIELDS.ISNEW}>
									{({ form, ...formik }) => (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												color: "grey",
											}}
										>
											<Checkbox
												checked={values[FORM_FIELDS.ISNEW]}
												onChange={() =>
													form.setFieldValue(
														FORM_FIELDS.ISNEW,
														!values[FORM_FIELDS.ISNEW],
													)
												}
												label="novo"
											/>
											<label htmlFor="customCheckbox">Novo</label>
										</div>
									)}
								</Field>
							</div>
						</div>
						<div
							style={{
								...adminAddProductContainerStyles,
								justifyContent: "none",
							}}
						>
							<Field name={FORM_FIELDS.IMAGE}>
								{({ form }) => (
									<div style={{ height: "50%" }}>
										<div
											style={{
												color: "black",
												width: "100%",
												display: "flex",
												justifyContent: "center",
												marginBottom: "5px",
											}}
										>
											Naslovna slika
										</div>
										{image ? (
											<>
												<div
													style={{
														height: "50%",
														width: "100%",
														backgroundColor: "grey",
														borderRadius: "5px",
													}}
												>
													<img
														src={image}
														alt="Slika"
														style={{
															width: "100%",
															height: "100%",
															objectFit: "contain",
														}}
													></img>
												</div>
												<div
													style={{
														display: "flex",
														justifyContent: "flex-end",
														width: "100%",
														alignItems: "center",
														marginTop: "16px",
													}}
												>
													<Button
														variant="contained"
														color="customRed"
														onClick={() =>
															handleImageDelete(form, FORM_FIELDS.IMAGE)
														}
													>
														Izbriši sliku
													</Button>
												</div>
											</>
										) : (
											<div
												style={{
													width: "100%",
													height: "90%",
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													border: "1px solid grey",
												}}
											>
												<Button variant="contained" component="label">
													Dodaj sliku
													<input
														hidden
														accept="image/*"
														type="file"
														onChange={(event) => {
															const image = event.target.files[0];
															const imageRef = ref(
																STORAGE,
																`images/${image.name + v4()}`,
															);
															dispatch(
																setDataState(DATA_STATE.DATA_STATE_LOADING),
															);
															uploadBytes(imageRef, image)
																.then((response) => {
																	getDownloadURL(response.ref).then((url) => {
																		form.setFieldValue(FORM_FIELDS.IMAGE, url);
																		setImage(url);
																		const notificationPayload = {
																			text: "Slika je uspešno sačuvana!",
																			type: NOTIFICATION_TYPES.SUCCESS,
																		};
																		dispatch(
																			displayNotification(notificationPayload),
																		);
																		dispatch(
																			setDataState(DATA_STATE.DATA_STATE_OK),
																		);
																	});
																})
																.catch(() => {
																	const notificationPayload = {
																		text: "Došlo je do greške!",
																		type: NOTIFICATION_TYPES.ERROR,
																	};
																	dispatch(
																		displayNotification(notificationPayload),
																	);
																	dispatch(
																		setDataState(DATA_STATE.DATA_STATE_OK),
																	);
																});
														}}
													/>
												</Button>
											</div>
										)}
									</div>
								)}
							</Field>
							<Field name={FORM_FIELDS.SECOND_IMAGE}>
								{({ form }) => (
									<>
										<div style={{ height: "25%" }}>
											<div
												style={{
													color: "black",
													width: "100%",
													display: "flex",
													justifyContent: "center",
												}}
											>
												Slika 2
											</div>
											{image2 ? (
												<>
													<div
														style={{
															height: "50%",
															width: "100%",
															backgroundColor: "grey",
														}}
													>
														<img
															src={image2}
															alt="Slika"
															style={{
																width: "100%",
																height: "100%",
																objectFit: "contain",
															}}
														></img>
													</div>
													<div
														style={{
															display: "flex",
															justifyContent: "flex-end",
															alignItems: "center",
															marginTop: "16px",
														}}
													>
														<Button
															variant="contained"
															color="customRed"
															onClick={() =>
																handleImageDelete(
																	form,
																	FORM_FIELDS.SECOND_IMAGE,
																)
															}
														>
															Izbriši sliku
														</Button>
													</div>
												</>
											) : (
												<div
													style={{
														width: "100%",
														height: "80%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														border: "1px solid grey",
													}}
												>
													<Button variant="contained" component="label">
														Dodaj sliku
														<input
															hidden
															accept="image/*"
															type="file"
															onChange={(event) => {
																const image = event.target.files[0];
																const imageRef = ref(
																	STORAGE,
																	`images/${image.name + v4()}`,
																);
																dispatch(
																	setDataState(DATA_STATE.DATA_STATE_LOADING),
																);
																uploadBytes(imageRef, image)
																	.then((response) => {
																		getDownloadURL(response.ref).then((url) => {
																			form.setFieldValue(
																				FORM_FIELDS.SECOND_IMAGE,
																				url,
																			);
																			setImage2(url);
																			const notificationPayload = {
																				text: "Slika je uspešno sačuvana!",
																				type: NOTIFICATION_TYPES.SUCCESS,
																			};
																			dispatch(
																				displayNotification(
																					notificationPayload,
																				),
																			);
																			dispatch(
																				setDataState(DATA_STATE.DATA_STATE_OK),
																			);
																		});
																	})
																	.catch(() => {
																		const notificationPayload = {
																			text: "Došlo je do greške!",
																			type: NOTIFICATION_TYPES.ERROR,
																		};
																		dispatch(
																			displayNotification(notificationPayload),
																		);
																		dispatch(
																			setDataState(DATA_STATE.DATA_STATE_OK),
																		);
																	});
															}}
														/>
													</Button>
												</div>
											)}
										</div>
									</>
								)}
							</Field>
							<Field name={FORM_FIELDS.THIRD_IMAGE}>
								{({ form }) => (
									<>
										<div style={{ height: "25%" }}>
											<div
												style={{
													color: "black",
													width: "100%",
													display: "flex",
													justifyContent: "center",
												}}
											>
												Slika 3
											</div>
											{image3 ? (
												<>
													<div
														style={{
															height: "50%",
															width: "100%",
															backgroundColor: "grey",
														}}
													>
														<img
															src={image3}
															alt="Slika"
															style={{
																width: "100%",
																height: "100%",
																objectFit: "contain",
															}}
														></img>
													</div>
													<div
														style={{
															display: "flex",
															justifyContent: "flex-end",
															alignItems: "center",
															marginTop: "16px",
														}}
													>
														<Button
															variant="contained"
															color="customRed"
															onClick={() =>
																handleImageDelete(form, FORM_FIELDS.THIRD_IMAGE)
															}
														>
															Izbriši sliku
														</Button>
													</div>
												</>
											) : (
												<div
													style={{
														width: "100%",
														height: "80%",
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														border: "1px solid grey",
													}}
												>
													<Button variant="contained" component="label">
														Dodaj sliku
														<input
															hidden
															accept="image/*"
															type="file"
															onChange={(event) => {
																const image = event.target.files[0];
																const imageRef = ref(
																	STORAGE,
																	`images/${image.name + v4()}`,
																);
																dispatch(
																	setDataState(DATA_STATE.DATA_STATE_LOADING),
																);
																uploadBytes(imageRef, image)
																	.then((response) => {
																		getDownloadURL(response.ref).then((url) => {
																			form.setFieldValue(
																				FORM_FIELDS.THIRD_IMAGE,
																				url,
																			);
																			setImage3(url);
																			const notificationPayload = {
																				text: "Slika je uspešno sačuvana!",
																				type: NOTIFICATION_TYPES.SUCCESS,
																			};
																			dispatch(
																				displayNotification(
																					notificationPayload,
																				),
																			);
																			dispatch(
																				setDataState(DATA_STATE.DATA_STATE_OK),
																			);
																		});
																	})
																	.catch(() => {
																		const notificationPayload = {
																			text: "Došlo je do greške!",
																			type: NOTIFICATION_TYPES.ERROR,
																		};
																		dispatch(
																			displayNotification(notificationPayload),
																		);
																		dispatch(
																			setDataState(DATA_STATE.DATA_STATE_OK),
																		);
																	});
															}}
														/>
													</Button>
												</div>
											)}
										</div>
									</>
								)}
							</Field>
						</div>
						<div
							style={{
								...adminAddProductContainerStyles,
								justifyContent: "none",
								height: "50vh",
							}}
						>
							<Field>
								{({ form }) => (
									<div
										style={{
											minHeight: "60%",
											height: "fit-content: ",
											color: "black",
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<p>Dodaj Ukuse</p>
										<div style={{ width: "90%" }}>
											<input
												type="text"
												value={inputValue}
												onChange={handleInputChange}
												style={{ width: "70%", height: "45px" }}
												placeholder="Unesi ukus"
											/>
											<Button
												variant="contained"
												sx={{
													cursor: "pointer",
													width: "26%",
													marginLeft: "5px",
													height: "49px",
													fontSize: "0.7em",
												}}
												onClick={() => handleAddFlavor(form)}
											>
												{editIndex !== null ? "Sačuvaj ukus" : "Dodaj Ukus"}
											</Button>
											<div
												style={{
													minHeight: "220px",
													height: "fit-content",
													maxHeight: "550px",
													overflowY: "auto",
													border: "1px solid grey",
													marginTop: "10px",
												}}
											>
												{flavors.map((flavor, index) => (
													<div
														key={index}
														style={{
															display: "flex",
															width: "100%",
															justifyContent: "space-between",
														}}
													>
														<p style={{ marginLeft: "5px" }} key={index}>
															- {flavor}
														</p>
														<div
															style={{
																width: "40%",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
																marginRight: "5px",
															}}
														>
															<Button
																variant="contained"
																color="grey"
																style={{
																	width: "60px",
																	height: "50%",
																	cursor: "pointer",
																}}
																onClick={() => handleEditFlavor(index)}
															>
																Izmeni
															</Button>
															<Button
																variant="contained"
																color="grey"
																style={{
																	width: "60px",
																	height: "50%",
																	cursor: "pointer",
																}}
																onClick={() => handleDeleteFlavor(index, form)}
															>
																Izbriši
															</Button>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								)}
							</Field>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "10px",
								padding: "10px",
							}}
						>
							<Button
								variant="contained"
								onClick={() => onSubmit(values)}
								disabled={!isValid}
							>
								Dodaj novi proizvod
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AdminPanelAddNewProductForm;
