import { v4 } from "uuid";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { Formik, Field, Form } from "formik";
import { isDesktop } from "react-device-detect";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
	Button,
	Checkbox,
	Breadcrumbs,
	useMediaQuery,
	InputAdornment,
	CircularProgress,
} from "@mui/material";

import {
	ref,
	uploadBytes,
	deleteObject,
	getDownloadURL,
} from "firebase/storage";

import {
	setDataState,
	displayNotification,
} from "../../store/reducers/notificationSlice";

import {
	getProduct,
	editProduct,
	deleteProduct,
	deleteProductImage,
	updateProductStock,
} from "../adminPanel.actions";

import { STORAGE } from "../../firebase";
import backgroundImage from "../../media/dumbbells-gym.jpg";
import SelectFormik from "../../components/formik/SelectFormik";
import useGetAllBrands from "../../helpers/hooks/useGetAllBrands";
import useGetProductCategories from "./hooks/useGetProductCategories";
import InputFieldFormik from "../../components/formik/InputFieldFormik";
import { DATA_STATE, NOTIFICATION_TYPES } from "../../helpers/app.constants";
import SelectMultipleFormik from "../../components/formik/SelectMultipleFormik";
import ReactQuill from "react-quill";

const FORM_FIELDS = {
	TITLE: "title",
	DESCRIPTION: "description",
	PRICE: "price",
	CATEGORY: "category",
	BRAND: "brand",
	IMAGE: "image",
	QUANTITY: "quantity",
	DISCOUNT_AMOUNT: "discountAmount",
	ISNEW: "newProduct",
	SECOND_IMAGE: "secondImage",
	THIRD_IMAGE: "thirdImage",
	FLAVORS: "flavors",
};

const AdminPanelEditProductForm = () => {
	const navigate = useNavigate();
	const { categories } = useGetProductCategories();
	const brands = useGetAllBrands();
	const theme = useTheme();
	const { id } = useParams();
	const dispatch = useDispatch();
	const screenSize = useMediaQuery("(max-width: 950px)");

	const [image, setImage] = useState(null);
	const [image2, setImage2] = useState(null);
	const [image3, setImage3] = useState(null);
	const [flavors, setFlavors] = useState([]);
	const [product, setProduct] = useState(null);
	const [inputValue, setInputValue] = useState("");
	const [editIndex, setEditIndex] = useState(null);
	const [outOfStock, setOutOfStock] = useState(false);

	useEffect(() => {
		if (id) {
			dispatch(getProduct(id))
				.then((data) => {
					setProduct(data.product);
					setImage(data.product.image);
					setImage2(data.product.secondImage);
					setImage3(data.product.thirdImage);
					setOutOfStock(data.product.outOfStock);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [id, dispatch]);

	const initialValues = useMemo(() => {
		if (product)
			return {
				[FORM_FIELDS.TITLE]: product.title,
				[FORM_FIELDS.DESCRIPTION]: product.description,
				[FORM_FIELDS.PRICE]: product.price,
				[FORM_FIELDS.CATEGORY]: product.category,
				[FORM_FIELDS.BRAND]: product.brand,
				[FORM_FIELDS.IMAGE]: product.image,
				[FORM_FIELDS.QUANTITY]: product.quantity,
				[FORM_FIELDS.DISCOUNT_AMOUNT]: product.discountAmount,
				[FORM_FIELDS.ISNEW]: product.newProduct,
				[FORM_FIELDS.SECOND_IMAGE]: product.secondImage,
				[FORM_FIELDS.THIRD_IMAGE]: product.thirdImage,
				[FORM_FIELDS.FLAVORS]: product.flavors,
			};
	}, [product]);

	useEffect(() => {
		if (initialValues) {
			setFlavors(initialValues[FORM_FIELDS.FLAVORS]);
		}
	}, [initialValues]);

	const validationSchema = Yup.object().shape({
		[FORM_FIELDS.TITLE]: Yup.string().required("Proizvod mora imati ime!"),
		[FORM_FIELDS.DESCRIPTION]: Yup.string().required(
			"Proizvod mora imati opis!",
		),
		[FORM_FIELDS.PRICE]: Yup.number().required("Proizvod mora imati cenu!"),
		[FORM_FIELDS.CATEGORY]: Yup.array()
			.required("Proizvod mora imati kategoriju!")
			.min(1, "Proizvod mora imati bar jednu kategoriju!"),
		[FORM_FIELDS.BRAND]: Yup.string().required("Proizvod mora imati brend!"),
		[FORM_FIELDS.IMAGE]: Yup.string().required("Proizvod mora imati sliku!"),
		[FORM_FIELDS.QUANTITY]: Yup.number().required("Quantity is required!"),
	});

	const onSubmit = (formValues) => {
		const newProduct = {
			[FORM_FIELDS.TITLE]: formValues[FORM_FIELDS.TITLE],
			[FORM_FIELDS.DESCRIPTION]: formValues[FORM_FIELDS.DESCRIPTION],
			[FORM_FIELDS.PRICE]: parseInt(formValues[FORM_FIELDS.PRICE]),
			[FORM_FIELDS.CATEGORY]: formValues[FORM_FIELDS.CATEGORY],
			[FORM_FIELDS.BRAND]: formValues[FORM_FIELDS.BRAND],
			[FORM_FIELDS.IMAGE]: formValues[FORM_FIELDS.IMAGE],
			[FORM_FIELDS.QUANTITY]: parseInt(formValues[FORM_FIELDS.QUANTITY]),
			[FORM_FIELDS.DISCOUNT_AMOUNT]: parseInt(
				formValues[FORM_FIELDS.DISCOUNT_AMOUNT],
			),
			[FORM_FIELDS.ISNEW]: formValues[FORM_FIELDS.ISNEW],
			[FORM_FIELDS.SECOND_IMAGE]: formValues[FORM_FIELDS.SECOND_IMAGE],
			[FORM_FIELDS.THIRD_IMAGE]: formValues[FORM_FIELDS.THIRD_IMAGE],
			[FORM_FIELDS.FLAVORS]: formValues[FORM_FIELDS.FLAVORS],
		};
		dispatch(editProduct(product._id, newProduct)).then(() =>
			navigate("/admin/admin-panel"),
		);
	};

	const handleProductDelete = (id) => {
		dispatch(deleteProduct(id)).then(() => navigate("/admin/admin-panel"));
	};

	const handleImageDelete = (form, field) => {
		let imageRef = null;
		if (field === FORM_FIELDS.IMAGE) {
			imageRef = ref(STORAGE, image);
		} else if (field === FORM_FIELDS.SECOND_IMAGE) {
			imageRef = ref(STORAGE, image2);
		} else if (field === FORM_FIELDS.THIRD_IMAGE) {
			imageRef = ref(STORAGE, image3);
		}
		deleteObject(imageRef)
			.then(() => {
				if (field === FORM_FIELDS.IMAGE) {
					setImage(null);
				} else if (field === FORM_FIELDS.SECOND_IMAGE) {
					setImage2(null);
				} else if (field === FORM_FIELDS.THIRD_IMAGE) {
					setImage3(null);
				}
				form.setFieldValue(field, null);
				dispatch(deleteProductImage(id, field)).then(() => {
					const notificationPayload = {
						text: "Slika je uspešno izbrisana!",
						type: NOTIFICATION_TYPES.SUCCESS,
					};
					dispatch(displayNotification(notificationPayload));
				});
			})
			.catch(() => {
				const notificationPayload = {
					text: "Došlo je do greške!",
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	//flavor

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleAddFlavor = (form) => {
		if (inputValue.trim() !== "") {
			if (editIndex !== null) {
				const updatedFlavors = flavors.map((flavor, index) =>
					index === editIndex ? inputValue : flavor,
				);
				setFlavors(updatedFlavors);
				form.setFieldValue(FORM_FIELDS.FLAVORS, updatedFlavors);
				setEditIndex(null);
			} else {
				setFlavors([...flavors, inputValue]);
				form.setFieldValue(FORM_FIELDS.FLAVORS, [...flavors, inputValue]);
			}

			setInputValue("");
		}
	};

	const handleEditFlavor = (index) => {
		setInputValue(flavors[index]);
		setEditIndex(index);
	};

	const handleDeleteFlavor = (index, form) => {
		const updatedFlavors = flavors.filter((flavor, i) => i !== index);
		setFlavors(updatedFlavors);
		form.setFieldValue(FORM_FIELDS.FLAVORS, updatedFlavors);
	};

	const handleUpdateProductStock = (outOfStock) => {
		dispatch(updateProductStock(product._id, outOfStock))
			.then((response) => {
				setOutOfStock(outOfStock);
				const notificationPayload = {
					text: response,
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
			})
			.catch(() => {
				const notificationPayload = {
					text: "Došlo je do greške!",
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	const adminAddFormContainerStyles = {
		height: "94%",
		width: "100%",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		overflow: "auto",
		backgroundColor: "white",
		borderBottomLeftRadius: "5px",
		borderBottomRightRadius: "5px",
	};
	const adminInputContainerStyles = {
		minWidth: "150px",
		width: "49%",
	};

	const adminAddProductContainerStyles = {
		borderRadius: "5px",
		padding: "15px",
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		justifyContent: "space-between",
		width: "400px",
		height: "85vh",
		marginLeft: "10px",
	};

	const adminAddProductFormStyles = {
		gap: "5px",
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		height: "90%",
		paddingTop: screenSize ? "50px" : null,
	};

	const adminAddProductFieldContainerStyles = {
		display: "flex",
		justifyContent: "space-between",
	};

	if (!brands || !categories || !product) return null;

	return (
		isDesktop && (
			<div
				style={{
					width: "100%",
					height: "100vh",
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundImage: `url(${backgroundImage})`,
					minWidth: "600px",
				}}
			>
				<div
					style={{
						height: "80%",
						width: "96%",
						marginTop: 60,
						backgroundColor: theme.palette.primaryLighter.opacity95,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "center",
						borderRadius: "5px",
						color: theme.palette.white.main,
					}}
				>
					<div style={{ width: "97%" }}>
						<Breadcrumbs aria-label="breadcrumb">
							<p
								style={{ cursor: "pointer", color: "white" }}
								className="hover-style-link"
								onClick={() => navigate("/")}
							>
								Početna strana
							</p>
							<p
								style={{ cursor: "pointer", color: "white" }}
								className="hover-style-link"
								onClick={() => navigate("/admin/admin-panel")}
							>
								Admin
							</p>

							<p style={{ fontWeight: "bold", color: "white" }}>
								{" "}
								Izmeni proizvod: {product.title}
							</p>
						</Breadcrumbs>
					</div>
					<div style={adminAddFormContainerStyles}>
						<Formik
							validateOnMount
							initialValues={initialValues}
							validationSchema={validationSchema}
						>
							{({ values, isValid }) => (
								<Form style={adminAddProductFormStyles}>
									<div style={adminAddProductContainerStyles}>
										<div>
											<Field name={FORM_FIELDS.TITLE}>
												{({ form, ...formik }) => (
													<InputFieldFormik
														form={form}
														{...formik}
														label="Ime"
														sx={{ width: "100%" }}
														fullWidth
														size="small"
													/>
												)}
											</Field>
										</div>

										<div style={{ height: "190px", marginBottom: "10px" }}>
											<Field name={FORM_FIELDS.DESCRIPTION}>
												{({ field }) => (
													<ReactQuill
														style={{
															height: "150px",
															width: "100%",
															color: "black",
														}}
														value={field.value || ""}
														onChange={field.onChange(field.name)}
														placeholder={"Opis proizvoda"}
													/>
												)}
											</Field>
										</div>

										<div
											style={{
												...adminAddProductFieldContainerStyles,
												marginTop: "40px",
											}}
										>
											<Field name={FORM_FIELDS.PRICE}>
												{({ form, ...formik }) => (
													<InputFieldFormik
														form={form}
														{...formik}
														label="Cena"
														sx={adminInputContainerStyles}
														size="small"
														InputProps={{
															endAdornment: (
																<InputAdornment position="start">
																	rsd
																</InputAdornment>
															),
														}}
													/>
												)}
											</Field>
											<Field name={FORM_FIELDS.DISCOUNT_AMOUNT}>
												{({ form, ...formik }) => (
													<InputFieldFormik
														form={form}
														{...formik}
														label="Popust"
														sx={adminInputContainerStyles}
														size="small"
														InputProps={{
															endAdornment: (
																<InputAdornment position="start">
																	%
																</InputAdornment>
															),
														}}
													/>
												)}
											</Field>
										</div>
										<div
											style={{
												...adminAddProductFieldContainerStyles,
												marginBottom: "10px",
											}}
										>
											<Field name={FORM_FIELDS.BRAND}>
												{({ form, ...formik }) => (
													<SelectFormik
														values={brands}
														form={form}
														{...formik}
														title="Brend"
														edit={true}
													/>
												)}
											</Field>
										</div>
										{!categories ? (
											<CircularProgress />
										) : (
											<Field name={FORM_FIELDS.CATEGORY}>
												{({ form, ...formik }) => (
													<SelectMultipleFormik
														values={categories}
														form={form}
														{...formik}
														title="Kategorija"
														edit={true}
													/>
												)}
											</Field>
										)}
										<div
											style={{
												...adminAddProductFieldContainerStyles,
												justifyContent: "space-around",
												marginTop: "5px",
											}}
										>
											<Field name={FORM_FIELDS.ISNEW}>
												{({ form, ...formik }) => (
													<div
														style={{
															display: "flex",
															justifyContent: "flex-end",
															alignItems: "center",
															color: "grey",
														}}
													>
														<Checkbox
															checked={values[FORM_FIELDS.ISNEW]}
															onChange={() =>
																form.setFieldValue(
																	FORM_FIELDS.ISNEW,
																	!values[FORM_FIELDS.ISNEW],
																)
															}
															label="novo"
														/>
														<label htmlFor="customCheckbox">Novo</label>
													</div>
												)}
											</Field>
										</div>
										<div
											style={{
												width: "100%",
												display: "flex",
												justifyContent: "space-around",
												alignItems: "center",
												marginTop: "10px",
											}}
										>
											<Button
												variant="contained"
												onClick={() => onSubmit(values)}
												disabled={!isValid}
											>
												Sačuvaj izmene
											</Button>
											<Button
												variant="contained"
												onClick={() => handleProductDelete(product._id)}
												color="customRed"
											>
												Izbriši proizvod
											</Button>
										</div>
									</div>
									<div
										style={{
											...adminAddProductContainerStyles,
											justifyContent: "space-evenly",
											display: "flex",
											alignItems: "center",
											height: "75vh",
										}}
									>
										<Field name={FORM_FIELDS.IMAGE}>
											{({ form }) => (
												<div style={{ height: "50%", width: "80%" }}>
													<div
														style={{
															color: "black",
															width: "100%",
															display: "flex",
															justifyContent: "center",
															marginBottom: "5px",
														}}
													>
														Naslovna slika
													</div>
													{image ? (
														<>
															<div
																style={{
																	height: "50%",
																	width: "100%",
																	backgroundColor: "grey",
																	borderRadius: "5px",
																}}
															>
																<img
																	src={image}
																	alt="Slika"
																	style={{
																		width: "100%",
																		height: "100%",
																		objectFit: "contain",
																	}}
																></img>
															</div>
															<div
																style={{
																	display: "flex",
																	justifyContent: "center",
																	width: "100%",
																	alignItems: "center",
																	marginTop: "16px",
																}}
															>
																<Button
																	variant="contained"
																	color="customRed"
																	onClick={() =>
																		handleImageDelete(form, FORM_FIELDS.IMAGE)
																	}
																>
																	Izbriši sliku
																</Button>
															</div>
														</>
													) : (
														<div
															style={{
																width: "100%",
																height: "90%",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
																border: "1px solid grey",
															}}
														>
															<Button variant="contained" component="label">
																Dodaj sliku
																<input
																	hidden
																	accept="image/*"
																	type="file"
																	onChange={(event) => {
																		const image = event.target.files[0];
																		const imageRef = ref(
																			STORAGE,
																			`images/${image.name + v4()}`,
																		);
																		dispatch(
																			setDataState(
																				DATA_STATE.DATA_STATE_LOADING,
																			),
																		);
																		uploadBytes(imageRef, image)
																			.then((response) => {
																				getDownloadURL(response.ref).then(
																					(url) => {
																						form.setFieldValue(
																							FORM_FIELDS.IMAGE,
																							url,
																						);
																						setImage(url);
																						const notificationPayload = {
																							text: "Slika je uspešno sačuvana!",
																							type: NOTIFICATION_TYPES.SUCCESS,
																						};
																						dispatch(
																							displayNotification(
																								notificationPayload,
																							),
																						);
																						dispatch(
																							setDataState(
																								DATA_STATE.DATA_STATE_OK,
																							),
																						);
																					},
																				);
																			})
																			.catch(() => {
																				const notificationPayload = {
																					text: "Došlo je do greške!",
																					type: NOTIFICATION_TYPES.ERROR,
																				};
																				dispatch(
																					displayNotification(
																						notificationPayload,
																					),
																				);
																				dispatch(
																					setDataState(
																						DATA_STATE.DATA_STATE_OK,
																					),
																				);
																			});
																	}}
																/>
															</Button>
														</div>
													)}
												</div>
											)}
										</Field>
										<Field name={FORM_FIELDS.SECOND_IMAGE}>
											{({ form }) => (
												<>
													<div style={{ height: "25%", width: "60%" }}>
														<div
															style={{
																color: "black",
																width: "100%",
																display: "flex",
																justifyContent: "center",
															}}
														>
															Slika 2
														</div>
														{image2 ? (
															<>
																<div
																	style={{
																		height: "50%",
																		width: "100%",
																		backgroundColor: "grey",
																	}}
																>
																	<img
																		src={image2}
																		alt="Slika"
																		style={{
																			width: "100%",
																			height: "100%",
																			objectFit: "contain",
																		}}
																	></img>
																</div>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																		marginTop: "16px",
																	}}
																>
																	<Button
																		variant="contained"
																		color="customRed"
																		onClick={() =>
																			handleImageDelete(
																				form,
																				FORM_FIELDS.SECOND_IMAGE,
																			)
																		}
																	>
																		Izbriši sliku
																	</Button>
																</div>
															</>
														) : (
															<div
																style={{
																	width: "100%",
																	height: "80%",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																	border: "1px solid grey",
																}}
															>
																<Button variant="contained" component="label">
																	Dodaj sliku
																	<input
																		hidden
																		accept="image/*"
																		type="file"
																		onChange={(event) => {
																			const image = event.target.files[0];
																			const imageRef = ref(
																				STORAGE,
																				`images/${image.name + v4()}`,
																			);
																			dispatch(
																				setDataState(
																					DATA_STATE.DATA_STATE_LOADING,
																				),
																			);
																			uploadBytes(imageRef, image)
																				.then((response) => {
																					getDownloadURL(response.ref).then(
																						(url) => {
																							form.setFieldValue(
																								FORM_FIELDS.SECOND_IMAGE,
																								url,
																							);
																							setImage2(url);
																							const notificationPayload = {
																								text: "Slika je uspešno sačuvana!",
																								type: NOTIFICATION_TYPES.SUCCESS,
																							};
																							dispatch(
																								displayNotification(
																									notificationPayload,
																								),
																							);
																							dispatch(
																								setDataState(
																									DATA_STATE.DATA_STATE_OK,
																								),
																							);
																						},
																					);
																				})
																				.catch(() => {
																					const notificationPayload = {
																						text: "Došlo je do greške!",
																						type: NOTIFICATION_TYPES.ERROR,
																					};
																					dispatch(
																						displayNotification(
																							notificationPayload,
																						),
																					);
																					dispatch(
																						setDataState(
																							DATA_STATE.DATA_STATE_OK,
																						),
																					);
																				});
																		}}
																	/>
																</Button>
															</div>
														)}
													</div>
												</>
											)}
										</Field>
										<Field name={FORM_FIELDS.THIRD_IMAGE}>
											{({ form }) => (
												<>
													<div style={{ height: "25%", width: "60%" }}>
														<div
															style={{
																color: "black",
																width: "100%",
																display: "flex",
																justifyContent: "center",
															}}
														>
															Slika 3
														</div>
														{image3 ? (
															<>
																<div
																	style={{
																		height: "50%",
																		width: "100%",
																		backgroundColor: "grey",
																	}}
																>
																	<img
																		src={image3}
																		alt="Slika"
																		style={{
																			width: "100%",
																			height: "100%",
																			objectFit: "contain",
																		}}
																	></img>
																</div>
																<div
																	style={{
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																		marginTop: "16px",
																	}}
																>
																	<Button
																		variant="contained"
																		color="customRed"
																		onClick={() =>
																			handleImageDelete(
																				form,
																				FORM_FIELDS.THIRD_IMAGE,
																			)
																		}
																	>
																		Izbriši sliku
																	</Button>
																</div>
															</>
														) : (
															<div
																style={{
																	width: "100%",
																	height: "80%",
																	display: "flex",
																	justifyContent: "center",
																	alignItems: "center",
																	border: "1px solid grey",
																}}
															>
																<Button variant="contained" component="label">
																	Dodaj sliku
																	<input
																		hidden
																		accept="image/*"
																		type="file"
																		onChange={(event) => {
																			const image = event.target.files[0];
																			const imageRef = ref(
																				STORAGE,
																				`images/${image.name + v4()}`,
																			);
																			dispatch(
																				setDataState(
																					DATA_STATE.DATA_STATE_LOADING,
																				),
																			);
																			uploadBytes(imageRef, image)
																				.then((response) => {
																					getDownloadURL(response.ref).then(
																						(url) => {
																							form.setFieldValue(
																								FORM_FIELDS.THIRD_IMAGE,
																								url,
																							);
																							setImage3(url);
																							const notificationPayload = {
																								text: "Slika je uspešno sačuvana!",
																								type: NOTIFICATION_TYPES.SUCCESS,
																							};
																							dispatch(
																								displayNotification(
																									notificationPayload,
																								),
																							);
																							dispatch(
																								setDataState(
																									DATA_STATE.DATA_STATE_OK,
																								),
																							);
																						},
																					);
																				})
																				.catch(() => {
																					const notificationPayload = {
																						text: "Došlo je do greške!",
																						type: NOTIFICATION_TYPES.ERROR,
																					};
																					dispatch(
																						displayNotification(
																							notificationPayload,
																						),
																					);
																					dispatch(
																						setDataState(
																							DATA_STATE.DATA_STATE_OK,
																						),
																					);
																				});
																		}}
																	/>
																</Button>
															</div>
														)}
													</div>
												</>
											)}
										</Field>
									</div>
									<div
										style={{
											...adminAddProductContainerStyles,
											justifyContent: "none",
											height: "50vh",
										}}
									>
										<Field name={FORM_FIELDS.FLAVORS}>
											{({ field, form }) => (
												<div
													style={{
														minHeight: "60%",
														height: "fit-content",
														color: "black",
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
														flexDirection: "column",
													}}
												>
													<p>Dodaj Ukuse</p>
													<div style={{ width: "90%" }}>
														<input
															type="text"
															value={inputValue}
															onChange={handleInputChange}
															style={{ width: "70%", height: "45px" }}
															placeholder="Unesi ukus"
														/>
														<Button
															variant="contained"
															sx={{
																cursor: "pointer",
																width: "26%",
																marginLeft: "5px",
																height: "49px",
																fontSize: "0.7em",
															}}
															onClick={() => handleAddFlavor(form)}
														>
															{editIndex !== null
																? "Sačuvaj ukus"
																: "Dodaj Ukus"}
														</Button>
														<div
															style={{
																minHeight: "220px",
																height: "fit-content",
																maxHeight: "550px",
																overflowY: "auto",
																border: "1px solid grey",
																marginTop: "10px",
															}}
														>
															{field.value.map((flavor, index) => (
																<div
																	key={index}
																	style={{
																		display: "flex",
																		width: "100%",
																		justifyContent: "space-between",
																	}}
																>
																	<p style={{ marginLeft: "5px" }} key={index}>
																		- {flavor}
																	</p>
																	<div
																		style={{
																			width: "40%",
																			display: "flex",
																			justifyContent: "space-between",
																			alignItems: "center",
																			marginRight: "5px",
																		}}
																	>
																		<Button
																			variant="contained"
																			color="grey"
																			style={{
																				width: "60px",
																				height: "50%",
																				cursor: "pointer",
																			}}
																			onClick={() => handleEditFlavor(index)}
																		>
																			Izmeni
																		</Button>
																		<Button
																			variant="contained"
																			color="grey"
																			style={{
																				width: "60px",
																				height: "50%",
																				cursor: "pointer",
																			}}
																			onClick={() =>
																				handleDeleteFlavor(index, form)
																			}
																		>
																			Izbriši
																		</Button>
																	</div>
																</div>
															))}
														</div>
														<div
															style={{
																marginTop: "20px",
																display: "flex",
																justifyContent: "space-between",
																alignItems: "center",
																fontSize: "0.8em",
																fontWeight: "bold",
															}}
														>
															<p>
																{outOfStock ? (
																	<div style={{ color: "red" }}>
																		Proizvod nije na stanju!
																	</div>
																) : (
																	<div style={{ color: "green" }}>
																		Proizvod je na stanju!
																	</div>
																)}
															</p>
															<Button
																variant="contained"
																onClick={() =>
																	handleUpdateProductStock(!outOfStock)
																}
																style={{
																	width: "55%",
																	fontSize: "0.8em",
																}}
															>
																{outOfStock
																	? "Dodaj proizvod na stanje"
																	: "Ukloni proizvod sa stanja"}
															</Button>
														</div>
													</div>
												</div>
											)}
										</Field>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		)
	);
};

export default AdminPanelEditProductForm;
