import {
	Button,
	Dialog,
	TextField,
	DialogTitle,
	DialogActions,
	DialogContent,
	useMediaQuery,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";

import {
	addBrand,
	addCategory,
	deleteBrand,
	editBrandName,
	deleteCategory,
	editCategoryName,
	dispatchGetAllBrands,
	dispatchGetAllCategories,
} from "./adminPanel.actions";

import { NOTIFICATION_TYPES } from "../helpers/app.constants";
import { displayNotification } from "../store/reducers/notificationSlice";

function AdminPanelCategoriesBrands() {
	const dispatch = useDispatch();
	const isScreenSmall= useMediaQuery('(max-width: 1400px)');

	const [brands, setBrands] = useState(null);
	const [newName, setNewName] = useState(null);
	const [categories, setCategories] = useState(null);
	const [activeItem, setActiveItem] = useState(null);
	const [addNewBrandName, setAddNewBrandName] = useState("");
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [addNewCategoryName, setAddNewCategoryName] = useState("");

	useEffect(() => {
		dispatch(dispatchGetAllBrands()).then((data) => {
			setBrands(data);
		});
		dispatch(dispatchGetAllCategories()).then((data) => {
			setCategories(data);
		});
	}, [dispatch]);

	const handleItemEdit = (item, newName) => {
		if (item.type === "brand") {
			dispatch(editBrandName(item._id, newName)).then(() => {
				const notificationPayload = {
					text: "Ime je uspešno izmenjeno",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
				dispatch(dispatchGetAllBrands()).then((data) => {
					setBrands(data);
				});
				setEditDialogOpen(false);
			});
		} else if (item.type === "category") {
			dispatch(editCategoryName(item._id, newName)).then(() => {
				const notificationPayload = {
					text: "Ime je uspešno izmenjeno",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
				dispatch(dispatchGetAllCategories()).then((data) => {
					setCategories(data);
				});
				setEditDialogOpen(false);
			});
		}
	};

	const handleAddBrand = () => {
		dispatch(addBrand(addNewBrandName))
			.then(() => {
				const notificationPayload = {
					text: "Brend uspešno dodat",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
				dispatch(dispatchGetAllBrands()).then((data) => {
					setBrands(data);
				});
				setEditDialogOpen(false);
			})
			.catch((error) => {
				const notificationPayload = {
					text: error.response.data.error,
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	const handleDeleteBrand = (brandId) => {
		dispatch(deleteBrand(brandId))
			.then(() => {
				const notificationPayload = {
					text: "Brend uspešno izbrisan",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
				dispatch(dispatchGetAllBrands()).then((data) => {
					setBrands(data);
				});
				setEditDialogOpen(false);
			})
			.catch(() => {
				const notificationPayload = {
					text: "Došlo je do greške!",
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	const handleAddCategory = () => {
		dispatch(addCategory(addNewCategoryName))
			.then(() => {
				const notificationPayload = {
					text: "Kategorija uspešno dodata",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
				dispatch(dispatchGetAllCategories()).then((data) => {
					setCategories(data);
				});
				setEditDialogOpen(false);
			})
			.catch((error) => {
				const notificationPayload = {
					text: error.response.data.error,
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	const handleDeleteCategory = (categoryId) => {
		dispatch(deleteCategory(categoryId))
			.then(() => {
				const notificationPayload = {
					text: "Kategorija uspešno izbrisana",
					type: NOTIFICATION_TYPES.SUCCESS,
				};
				dispatch(displayNotification(notificationPayload));
				dispatch(dispatchGetAllCategories()).then((data) => {
					setCategories(data);
				});
				setEditDialogOpen(false);
			})
			.catch(() => {
				const notificationPayload = {
					text: "Došlo je do greške!",
					type: NOTIFICATION_TYPES.ERROR,
				};
				dispatch(displayNotification(notificationPayload));
			});
	};

	if (!brands || !categories) return null;
	return (
		isDesktop && (
			<div style={{ width: "100%", minHeight: "91.5%", height:"fit-content", backgroundColor: "white", overflow:"auto" }}>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						gridTemplateRows: "1fr",
						gap: "0px 0px",
						width: "100%",
						height: "100%",
					}}
				>
					<div
						style={{
							padding: "20px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
						}}
					>
						<div
							style={{
								height: "15%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<input
								type="text"
								value={addNewBrandName}
								onChange={(e) => setAddNewBrandName(e.target.value)}
								style={{ width: "70%", height: "45px" }}
								placeholder="Unesi brend"
							/>
							<Button
								variant="contained"
								sx={{
									cursor: "pointer",
									width: "26%",
									marginLeft: "5px",
									height: "49px",
									fontSize: isScreenSmall ? "0.75em" : (isDesktop ? "0.95em" : null),
								}}
								onClick={handleAddBrand}
								className="hover-style"
							>
								Dodaj Brend
							</Button>
						</div>
						<div
							style={{
								border: "1px solid grey",
								height: isScreenSmall ? "45%" : "50%",
								color: "black",
								padding: "10px 0 10px 0",
								overflow: "auto",
							}}
						>
							{brands.map((brand) => (
								<div
									key={brand.id}
									style={{
										marginLeft: "5px",
										height: "50px",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									{brand.name}
									<div
										style={{
											width: "30%",
											display: "flex",
											justifyContent: "flex-end",
										}}
									>
										<Button
											variant="contained"
											color="grey"
											style={{
												width: "60px",
												height: "50%",
												cursor: "pointer",
												marginRight: "15px",
											}}
											onClick={() => {
												setActiveItem({ ...brand, type: "brand" });
												setEditDialogOpen(true);
											}}
										>
											Izmeni
										</Button>
										<Button
											variant="contained"
											color="grey"
											style={{
												width: "60px",
												height: "50%",
												cursor: "pointer",
												marginRight: "10px",
											}}
											onClick={() => handleDeleteBrand(brand._id)}
										>
											Izbriši
										</Button>{" "}
									</div>
								</div>
							))}
						</div>
					</div>
					<div
						style={{
							padding: "20px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
						}}
					>
						<div
							style={{
								height: "15%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<input
								type="text"
								value={addNewCategoryName}
								onChange={(e) => setAddNewCategoryName(e.target.value)}
								style={{ width: "70%", height: "45px" }}
								placeholder="Unesi kategoriju"
							/>
							<Button
								variant="contained"
								sx={{
									cursor: "pointer",
									width: "26%",
									marginLeft: "5px",
									height: "49px",
									fontSize: isScreenSmall ? "0.75em" : (isDesktop ? "0.95em" : null),
								}}
								onClick={handleAddCategory}
								className="hover-style"
							>
								Dodaj Kategoriju
							</Button>
						</div>
						<div
							style={{
								border: "1px solid grey",
								height: isScreenSmall ? "45%" : "50%",
								color: "black",
								padding: "10px 0 10px 0",
								overflow: "auto",
							}}
						>
							{categories.map((categories) => (
								<div
									key={categories.id}
									style={{
										marginLeft: "5px",
										height: "50px",
										display: "flex",
										justifyContent: "space-between",
									}}
								>
									{categories.name}
									<div
										style={{
											width: "30%",
											display: "flex",
											justifyContent: "flex-end",
										}}
									>
										<Button
											variant="contained"
											color="grey"
											style={{
												width: "60px",
												height: "50%",
												cursor: "pointer",
												marginRight: "15px",
											}}
											onClick={() => {
												setActiveItem({ ...categories, type: "category" });
												setEditDialogOpen(true);
											}}
										>
											Izmeni
										</Button>
										<Button
											variant="contained"
											color="grey"
											style={{
												width: "60px",
												height: "50%",
												cursor: "pointer",
												marginRight: "10px",
											}}
											onClick={() => handleDeleteCategory(categories._id)}
										>
											Izbriši
										</Button>{" "}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
				{activeItem && (
					<Dialog
						maxWidth="xs"
						fullWidth
						open={editDialogOpen}
						onClose={() => setEditDialogOpen(false)}
					>
						<DialogTitle>Izmeni naziv</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								margin="dense"
								id="name"
								label="Naziv"
								type="email"
								fullWidth
								variant="standard"
								placeholder={activeItem.name}
								onChange={(e) => setNewName(e.target.value)}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								variant="outlined"
								color="customRed"
								onClick={() => setEditDialogOpen(false)}
							>
								Odustani
							</Button>
							<Button
								variant="contained"
								onClick={() => handleItemEdit(activeItem, newName)}
							>
								Sačuvaj
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		)
	);
}

export default AdminPanelCategoriesBrands;
