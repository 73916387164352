import { Formik, Form } from "formik";
import { Breadcrumbs } from "@mui/material";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { isDesktop, isMobile } from "react-device-detect";
import CustomField from "../other/CustomField";
import CustomLoginButton from "../other/CustomButton";
import {
	DATA_STATE,
	NOTIFICATION_TYPES,
	SERVER_URL,
} from "../../helpers/app.constants";
import {
	displayNotification,
	setDataState,
} from "../../store/reducers/notificationSlice";
import "../../App.css";

function Register({ width, size, error, margin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		username: Yup.string().required("Korisničko ime je obavezno"),
		email: Yup.string()
			.required("Email je obavezan")
			.matches(
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
				"Nevažeći email format",
			),
		password: Yup.string()
			.min(4, "Lozinka mora biti duža od 4 karaktera!")
			.required("Lozinka je obavezna"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Lozinke moraju biti iste!")
			.required("Potvrdite lozinku!"),
		name: Yup.string().required("Ime je obavezno"),
		lastName: Yup.string().required("Prezime je obavezno"),
		phone: Yup.string()
			.required("Telefon je obavezan")
			.matches(/^\d{10}$|^\d{6}$|^\d{7}$/, "Nevažeći broj"),
		address: Yup.string().required("Adresa je obavezna"),
		zipCode: Yup.string()
			.required("Poštanski broj je obavezan")
			.matches(/^\d{5}$/, "Poštanski broj mora imati 5 cifara"),
		city: Yup.string().required("Grad je obavezan"),
	});

	// Initial form values
	const initialValues = {
		email: "",
		username: "",
		password: "",
		confirmPassword: "",
		companyName: "",
		name: "",
		lastName: "",
		phone: "",
		address: "",
		zipCode: "",
		city: "",
	};

	const handleSubmit = async (values, { setErrors }) => {
		try {
			dispatch(setDataState(DATA_STATE.DATA_STATE_LOADING));
			await axios.post(`${SERVER_URL}user/registeruser`, values);
			const notificationPayload = {
				text: "Uspešno ste se registrovali!",
				type: NOTIFICATION_TYPES.SUCCESS,
			};
			dispatch(displayNotification(notificationPayload));
			dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
			navigate("/");
		} catch (error) {
			console.log(error);
			setErrors({});
			if (error.response && error.response.data.error) {
				const errorMessage = error.response.data.error;

				if (errorMessage.includes("Email is already registered")) {
					setErrors({
						email: "Email je već registrovan",
					});
				} else if (
					errorMessage.includes("Phone number is already registered")
				) {
					setErrors({
						phone: "Broj je već registrovan",
					});
				} else if (errorMessage === "Internal server error") {
					const notificationPayload = {
						text: "Došlo je do greške sa serverom!",
						type: NOTIFICATION_TYPES.ERROR,
					};
					dispatch(displayNotification(notificationPayload));
				}
			}
		}
		dispatch(setDataState(DATA_STATE.DATA_STATE_OK));
	};

	//styles
	const theme = useTheme();

	const registerContainerStyles = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		height: "fit-content",
		width: "95%",
		paddingTop: "3px",
		paddingBottom: "40px",
		// padding: "25px",
		borderRadius: "5px",
		backgroundColor: theme.palette.grey.darkerOpacity85,
		boxShadow: "0px 0px 3px 0.7px rgba(0, 0, 0, 0.9)",
	};

	const filterPageBreadcrumbsContainerStyles = {
		height: "4%",
		width: "100%",
	};

	const pStyles = {
		fontSize: isDesktop ? "1.5em" : "1.8em",
		color: "black",
	};

	const handleLinkClick = () => {
		navigate("/");
	};

	return (
		<div style={registerContainerStyles}>
			<div style={filterPageBreadcrumbsContainerStyles}>
				<div style={{ marginLeft: "20px" }}>
					<Breadcrumbs
						aria-label="breadcrumb"
						sx={{
							fontSize: isMobile && "0.7em",
						}}
					>
						<p
							style={{ color: "black", cursor: "pointer" }}
							className="hover-style-link"
							onClick={handleLinkClick}
						>
							Početna strana
						</p>
						<p style={{ color: "black", fontWeight: "bold" }}>Registracija</p>
					</Breadcrumbs>
				</div>
			</div>
			<p style={pStyles}>Registruj se</p>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{(formik) => (
					<Form>
						<CustomField
							name="username"
							label="Korisničko ime"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>

						<CustomField
							name="email"
							label="Email adresa"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>

						<CustomField
							name="password"
							label="Lozinka"
							size={size}
							error={error}
							margin={margin}
							password={true}
							width={width}
							type={"password"}
						/>

						<CustomField
							name="confirmPassword"
							label="Potvrdi lozinku"
							size={size}
							error={error}
							margin={margin}
							password={true}
							width={width}
							type={"password"}
						/>

						<CustomField
							name="companyName"
							label="Ime preduzeća(opciono)"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>

						<CustomField
							name="name"
							label="Ime"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>

						<CustomField
							name="lastName"
							label="Prezime"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>

						<CustomField
							name="phone"
							label="Telefon"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"number"}
						/>

						<CustomField
							name="address"
							label="Adresa"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>

						<CustomField
							name="zipCode"
							label="Poštanski broj"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"number"}
						/>

						<CustomField
							name="city"
							label="Grad"
							size={size}
							error={error}
							margin={margin}
							password={false}
							width={width}
							type={"text"}
						/>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								margin: "20px 0 20px 0",
							}}
						>
							<CustomLoginButton
								disabled={!formik.values.email || !formik.values.password}
								onClick={formik.handleSubmit}
								textButton={"Registruj se"}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default Register;
