import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { useCallback, useEffect, useState } from "react";

import AdminPanelPromoCode from "./AdminPanelPromoCode";
import backgroundImage from "../media/dumbbells-gym.jpg";
import useGetAllBrands from "../helpers/hooks/useGetAllBrands";
import { selectUserIfAdmin } from "../store/reducers/userSlice";
import AdminPanelOrdersContainer from "./AdminPanelOrdersContainer";
import AdminPanelCategoriesBrands from "./AdminPanelCategoriesBrands";
import AdminPanelProductsListContainer from "./AdminPanelProductsListContainer";
import useGetProductCategories from "./components/hooks/useGetProductCategories";
import AdminPanelAddNewProductForm from "./components/AdminPanelAddNewProductForm";
import AdminPanelUsers from "./AdminPanelUsers";
import AdminPanelGallery from "./AdminPanelGallery";

export const ADMIN_PANEL_TABS = {
	ORDERS: "ORDERS",
	ADD_PRODUCT: "ADD_PRODUCT",
	ALL_PRODUCTS: "ALL_PRODUCTS",
	CATEGORIESBRANDS: "CATEGORIESBRANDS",
	PROMOCODE: "PROMOCODE",
	USERS: "USERS",
	GALLERY: "GALLERY",
};

const AdminPanelContainer = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const brands = useGetAllBrands();
	const { categories } = useGetProductCategories();

	const isAdminLoggedIn = useSelector(selectUserIfAdmin);

	const [activeTab, setActiveTab] = useState(ADMIN_PANEL_TABS.ALL_PRODUCTS);

	const adminPanelTabContainer = {
		width: "200px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRight: `1px solid ${theme.palette.grey.opacity70}`,
		height: "100%",
		cursor: "pointer",
	};

	const handleSettingActiveTab = useCallback((activeTab) => {
		setActiveTab(activeTab);
	}, []);

	useEffect(() => {
		if (!isAdminLoggedIn) navigate("/admin");
	}, [isAdminLoggedIn, navigate]);

	const handleAdminTabSwitch = (page) => {
		switch (page) {
			case ADMIN_PANEL_TABS.ALL_PRODUCTS:
				return (
					<AdminPanelProductsListContainer
						brands={brands}
						categories={categories}
					/>
				);
			case ADMIN_PANEL_TABS.ADD_PRODUCT:
				return (
					<AdminPanelAddNewProductForm
						brands={brands}
						categories={categories}
						handleSettingActiveTab={handleSettingActiveTab}
					/>
				);
			case ADMIN_PANEL_TABS.ORDERS:
				return <AdminPanelOrdersContainer />;
			case ADMIN_PANEL_TABS.CATEGORIESBRANDS:
				return <AdminPanelCategoriesBrands />;
			case ADMIN_PANEL_TABS.PROMOCODE:
				return <AdminPanelPromoCode />;
			case ADMIN_PANEL_TABS.USERS:
				return <AdminPanelUsers />;
			case ADMIN_PANEL_TABS.GALLERY:
				return <AdminPanelGallery />;
			default:
				return (
					<AdminPanelAddNewProductForm
						brands={brands}
						categories={categories}
						handleSettingActiveTab={handleSettingActiveTab}
					/>
				);
		}
	};

	if (!isAdminLoggedIn) return null;

	return (
		isDesktop && (
			<div
				style={{
					position: "relative",
					height: "100vh",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundAttachment: "fixed",
					minWidth: "1080px",
					overflow: "auto",
				}}
			>
				<div
					style={{
						height: "80%",
						width: "96%",
						marginTop: 60,
						backgroundColor: theme.palette.primaryLighter.opacity95,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "center",
						borderRadius: "5px",
						color: theme.palette.white.main,
						boxShadow: "0px 0px 3px 0.7px rgba(0, 0, 0, 0.9)",
					}}
				>
					<div
						style={{
							borderBottom: `1px solid ${theme.palette.grey.opacity70}`,
							backgroundColor: theme.palette.grey.darkerOpacity85,
							height: "70px",
							width: "100%",
							borderTopLeftRadius: "5px",
							borderTopRightRadius: "5px",
							boxShadow: " inset 0px 0px 18px -6px rgba(0,0,0,1)",
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.ALL_PRODUCTS &&
									theme.palette.primaryLighter.opacity95,
								border:
									activeTab === ADMIN_PANEL_TABS.ALL_PRODUCTS &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.ALL_PRODUCTS)}
						>
							Svi proizvodi
						</div>
						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.ADD_PRODUCT &&
									theme.palette.primaryLighter.opacity95,
								borderTopLeftRadius:
									activeTab === ADMIN_PANEL_TABS.ADD_PRODUCT && "5px",
								border:
									activeTab === ADMIN_PANEL_TABS.ADD_PRODUCT &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.ADD_PRODUCT)}
						>
							Dodaj Proizvod
						</div>

						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.CATEGORIESBRANDS &&
									theme.palette.primaryLighter.opacity95,
								border:
									activeTab === ADMIN_PANEL_TABS.CATEGORIESBRANDS &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.CATEGORIESBRANDS)}
						>
							Brendovi i Kategorije
						</div>
						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.ORDERS &&
									theme.palette.primaryLighter.opacity95,
								border:
									activeTab === ADMIN_PANEL_TABS.ORDERS &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.ORDERS)}
						>
							Narudžbine
						</div>
						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.PROMOCODE &&
									theme.palette.primaryLighter.opacity95,
								border:
									activeTab === ADMIN_PANEL_TABS.PROMOCODE &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.PROMOCODE)}
						>
							Promo Kod
						</div>
						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.USERS &&
									theme.palette.primaryLighter.opacity95,
								border:
									activeTab === ADMIN_PANEL_TABS.USERS &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.USERS)}
						>
							Korisnici
						</div>
						<div
							style={{
								...adminPanelTabContainer,
								backgroundColor:
									activeTab === ADMIN_PANEL_TABS.GALLERY &&
									theme.palette.primaryLighter.opacity95,
								border:
									activeTab === ADMIN_PANEL_TABS.GALLERY &&
									`1.5px solid ${theme.palette.secondary.main}`,
							}}
							onClick={() => setActiveTab(ADMIN_PANEL_TABS.GALLERY)}
						>
							Galerija
						</div>
					</div>

					{categories && brands ? handleAdminTabSwitch(activeTab) : null}
				</div>
			</div>
		)
	);
};

export default AdminPanelContainer;
