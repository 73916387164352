import ImageUploader from "../components/other/ImagesUploader";

function AdminPanelGallery() {
	const adminPanelGalleryTabContainer = {
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
		gridTemplateRows: "1fr 0.1fr",
		gap: "0px 0px",
		overflow: "auto",
	};
	const adminPanelGalleryContainer = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	};

	const adminPanelGalleryInnerContainer = {
		width: "80%",
		height: "90%",
		display: "flex",
		flexDirection: "column",
	};

	const adminPanelGalleryTitle = {
		fontWeight: "bold",
		fontSize: "1.2em",
		width: "100%",
		textAlign: "center",
		color: "black",
		marginBottom: "10px",
	};

	const adminPanelGalleryPicsContainer = {
		width: "100%",
		height: "95%",
		border: "1px solid grey",
	};

	return (
		<div style={adminPanelGalleryTabContainer}>
			<div style={adminPanelGalleryContainer}>
				<div style={adminPanelGalleryInnerContainer}>
					<div style={adminPanelGalleryTitle}>DESKTOP Galerija</div>
					<div style={adminPanelGalleryPicsContainer}>
						<ImageUploader />
					</div>
				</div>
			</div>
			<div style={adminPanelGalleryContainer}>
				<div style={adminPanelGalleryInnerContainer}>
					<div style={adminPanelGalleryTitle}>Mobile Galerija </div>
					<div style={adminPanelGalleryPicsContainer}></div>
				</div>
			</div>
		</div>
	);
}

export default AdminPanelGallery;
